import { createRouter, createWebHashHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import SeriesView from "@/views/SeriesView.vue";
import ProductView from "@/views/ProductView.vue";
import ContactUsview from "@/views/ContactUsView.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import MediaCoView from "@/views/MediaCoView.vue";
import RecruitView from "@/views/RecruitView.vue";
import SecurityCodeView from "@/views/SecurityCodeView.vue";
import StoreInfoView from "@/views/StoreInfoView.vue";



const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexView
  },
  {
    path: '/collect',
    name: 'collect',
    component: SeriesView
  },
  {
    path: '/product',
    name: 'product',
    component: ProductView
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUsview
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: AboutUsView
  },
  {
    path: '/mediaCo',
    name: 'mediaCo',
    component: MediaCoView
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: RecruitView
  },
  {
    path: '/securityCode',
    name: 'securityCode',
    component: SecurityCodeView
  },
  {
    path: '/storeInfo',
    name: 'storeInfo',
    component: StoreInfoView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
