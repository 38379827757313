<template>
  <div class="product-carousel">
    <a-carousel
        :style="{
      width: '100%',
      height:'55rem',
    }"
        indicator-type="never"
        :current="current_show"
        @change="changeSelected"
        animation-name="fade"
        move-speed="1000"
    >
      <a-carousel-item v-for="image in images" :style="{
         'display': 'flex',
          'align-items': 'center'
      }">
        <img
            :src="image"
            :style="{
              width: '53rem',
              height: '53rem'
        }"
        />
      </a-carousel-item>
    </a-carousel>
    <div>
      <div class="marquee">
        <div class="scroll-arrow scroll-left" @click="scrollLeft()">
          <icon-left />
        </div>
        <div class="scroll-marquee" ref="scrollMarquee">
          <template v-for="(item, index) in images">
            <a
                :class="[
                  'scroll-item',
                   selected_index === index?'scroll-item-selected':''
               ]"
                :style="[
                  {
                    flex:'1 0 calc(100% / 5)'
                  }
                ]"
                @click="changeSelected(index)"
            >
              <img :src="item" alt=""/>
            </a>
          </template>
            <a
                v-if="images.length!==0 && images.length < 5" v-for="_ in 5 - images.length"
                :class="[
                  'scroll-item',
               ]"
                :style="[
                  {
                    flex:'1 0 calc(100% / 5)'
                  }
                ]"
            />
        </div>
        <div class="scroll-arrow scroll-right" @click="scrollRight()">
          <icon-right />
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import router from "@/router";
import {defineComponent, ref} from "vue";
import Marquee from "@/components/Marquee.vue";

const components = defineComponent({
  Marquee
})

const props = defineProps({
  images:{
    type:Array
  }
})


const scrollMarquee = ref();
const selected_index = ref(0)
const current_show = ref(0)

const scrollLeft = function(){
  scrollMarquee.value.scrollTo({
    left: scrollMarquee.value.scrollLeft - scrollMarquee.value.children[0].offsetWidth,
    behavior: 'smooth'
  })
}

const scrollRight = function(){
  scrollMarquee.value.scrollTo({
    left: scrollMarquee.value.scrollLeft + scrollMarquee.value.children[0].offsetWidth,
    behavior: 'smooth'
  })
}

const changeSelected = function(index){
  selected_index.value = index
  current_show.value = index + 1
}

changeSelected(0)

</script>

<style scoped>
.marquee{
  display: flex;
  align-items: center;
  position: relative;
}

.marquee:before{
  position: absolute;
  left: 4.9rem;
  top: 0;
  width: 0;
  height: 100%;
  border-left: 0.5rem solid #fff;
  content: '';
  display: block;
  z-index: 99;
}

.marquee:after{
  position: absolute;
  right: 4.9rem;
  top: 0;
  width: 0;
  height: 100%;
  border-right: 0.5rem solid #fff;
  content: '';
  display: block;
  z-index: 99;
}

.marquee .scroll-marquee{
  width: calc(100% - 2px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
}

.marquee .scroll-item{
  position: relative;
  background-position: center;
  background-size: cover;
  border-left: none;
  padding: 0 1.6rem;
  box-sizing: border-box;
  cursor: pointer;
}

.marquee .scroll-item-selected{
  border-bottom: 0.3rem solid #000;
  z-index: 100;
}

.marquee .scroll-item:first-child{
  border-left: none;
}

.marquee .scroll-item img{
  width: 100%;
  max-width: 6rem;
  max-height: 6rem;
  object-fit: cover;
}

.marquee .scroll-arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  color: #000;
  cursor: pointer;
  text-align: center;
  font-size: 3rem;
  line-height: 1.3;
  user-select: none;
  padding-bottom: 0.5rem;
  font-weight: 900;
}
</style>
