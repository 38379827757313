<template>
  <div class="sort-checkbox">
  <a-collapse expand-icon-position="right" default-active-key="1">
    <a-collapse-item :header="title" key="1">
      <div class="items-box">
        <a-checkbox-group @change="change_sort" :default-value="default_select">
          <ul>
            <template v-if="data.length <= 4">
              <li v-for="(item,index) in data" :key="index">
                <a-checkbox :value="item.name" >{{item.name}}</a-checkbox>
              </li>
            </template>
            <template v-else>
              <template v-for="(item,index) in data" :key="index">
                <li v-if="index < 4">
                  <a-checkbox :value="item.name" >{{item.name}}</a-checkbox>
                </li>
              </template>
              <template v-for="(item,index) in data">
                <li :key="index" v-if="index >= 4 && show_more">
                  <a-checkbox :value="item.name" >{{item.name}}</a-checkbox>
                </li>
              </template>
                <li @click="change_show" style="cursor: pointer">
                  {{show_more?'- 更少':'+ 更多'}}
                </li>
            </template>
          </ul>
        </a-checkbox-group>
      </div>
    </a-collapse-item>
  </a-collapse>

  </div>

</template>

<script setup>
  import router from "@/router";
  import { ref } from "vue";
  const emits = defineEmits(['change'])
  const props = defineProps({
    title:{
      type: String,
      default: '筛选'
    },
    data:{
      type: Array,
      default: ()=>[]
    },
    type:{
      type:String
    }
  })

  const show_more = ref(false)
  const show_more_btn = ref(false)
  const default_select = ref([])

  const change_show = function (){
      show_more.value = !show_more.value
  }

  const change_sort = function (value ,ev){
    console.log(value)
    emits('change',props.type, value)
  }

  if(props.data.length > 4){
      show_more_btn.value = true
  }

  for (let i = 0; i < props.data.length; i++) {
    let item = props.data[i]
    if(item.selected){
      default_select.value.push(item.name)
    }
  }

</script>

<style scoped>
  .sort-checkbox{
      border-top: 0.1rem solid rgba(0,0,0,.3);
      padding: 3rem 0;
      margin-right: 1.5rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      letter-spacing: 0.032rem;
      color: #000;
  }

  .sort-checkbox:first-child{
      border-top: unset;
  }

  .sort-checkbox >>> .arco-collapse-item-header-title, .sort-checkbox >>> .arco-collapse-item-active > .arco-collapse-item-header .arco-collapse-item-header-title{
      display: block;
      font-weight: 700;
      position: relative;
      pointer-events: none;
      font-size: 1.6rem;
  }

  .sort-checkbox .hidden-box >>> .arco-collapse-item-header-title, .sort-checkbox .hidden-box >>> .arco-collapse-item-active > .arco-collapse-item-header .arco-collapse-item-header-title{
      font-weight: unset;
      font-size: 1.6rem;
  }

  .sort-checkbox >>> .arco-collapse-item-active > .arco-collapse-item-header{
    border: unset;
    margin: 0;
  }

  .sort-checkbox .items-box{
      overflow: hidden;
      transition: height .15s linear 0s;
  }

  .sort-checkbox .items-box ul{
      margin-top: 2rem;
      padding: unset;
      list-style: none;
      line-height: 1.8;
  }

  .sort-checkbox .items-box li{
      margin: unset;
      padding-bottom: 0.9rem;
      font-size: 1.6rem;
  }

  .sort-checkbox >>> .arco-checkbox-checked .arco-checkbox-icon, .sort-checkbox >>> .arco-checkbox-icon {
      width: 2rem;
      height: 2rem;
  }

  .sort-checkbox >>> .arco-checkbox-checked .arco-checkbox-icon-check{
      width: 1.2rem;
  }

  .sort-checkbox >>> .arco-checkbox-checked .arco-checkbox-icon{
      background-color: black;
  }

  .sort-checkbox >>> .arco-checkbox-label{
      font-size: 1.6rem;
  }

  .sort-checkbox >>> .arco-collapse-item-content{
      background-color: unset;
      padding: unset;
  }

  .sort-checkbox >>> .arco-collapse{
      border: unset;
      border-radius: unset;
  }

  .sort-checkbox >>> .arco-checkbox-label:hover {
      color: #757575;
  }

</style>
