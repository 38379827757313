<script setup>
import {defineComponent, ref} from "vue";
import Marquee from "@/components/Marquee.vue";
import MarqueeContent from "@/components/MarqueeContent.vue";
import MarqueeCard from "@/components/MarqueeCard.vue";
import Footer from "@/components/Footer.vue";
import {getAttributeHeader, getHomePage} from "@/api/api";
import router from "@/router";

const components = defineComponent({
  MarqueeCard,
  Marquee,
  MarqueeContent,
  Footer
})
const carousel = ref([]);
const marquee_unite = ref([]);
const special_product = ref([])
const magsafe = ref([])
const kits = ref([])
const hot = ref([])
const designer = ref([])
const blogger = ref([])
const carousel_out = ref({
  title:"",
  subtitle:"",
  btntitle:""
})

const special = ref([])
const handleChange=(value)=>{
  let item = carousel.value[value - 1]
  carousel_out.value.btntitle = item.btntitle
  carousel_out.value.subtitle = item.subtitle
  carousel_out.value.title = item.title
  carousel_out.value.attribute = item.attribute
}

const BloggerType2Name = function(type){
  switch (Number(type)){
    case 1:
      return "模特"
    case 2:
      return "时尚博主"
    case 3:
      return "日常博主"
    default:
      return "博主"
  }
};

(async()=>{
  getHomePage().then(res=>{
    console.log("请求首页", res)
    let data = res.data
    /* 轮播 */
    for (let i = 0; i < data.carousel.length; i++) {
      let item = data.carousel[i]
      carousel.value.push({
        image:item.image,
        title:item.title,
        subtitle:item.description,
        attribute:item.attribute,
        btntitle:'立即选购',
        id:item.id
      })
    }
    handleChange(1)
    /* 联名 */
    for (let i = 0; i < data.co.length; i++) {
      let item = data.co[i]
      marquee_unite.value.push({
        id:item.id,
        name:item.name,
        img:item.image
      })
    }
    console.log(marquee_unite)
    /* 精选系列 */
    for (let i = 0; i < data.special.length; i++) {
      let item = data.special[i]
      special.value.push({
        attribute_id:item.id,
        name:item.name,
        img:item.image
      })
    }
    /* 精选产品 */
    for (let i = 0; i < data.special_product.length; i++) {
      let item = data.special_product[i]
      special_product.value.push({
        spu_id:item.spu_id,
        title:item.name,
        img:item.image
      })
    }

    /* 磁吸 */
    for (let i = 0; i < data.magsafe.length; i++) {
      let item = data.magsafe[i]
      magsafe.value.push({
        spu_id:item.spu_id,
        title:item.name,
        img:item.image
      })
    }

    /* 配件 */
    for (let i = 0; i < data.kits.length; i++) {
      let item = data.kits[i]
      kits.value.push({
        spu_id:item.spu_id,
        title:item.name,
        img:item.image
      })
    }

    /* 热销 */
    for (let i = 0; i < data.hot.length; i++) {
      let item = data.hot[i]
      hot.value.push({
        spu_id:item.spu_id,
        title:item.name,
        img:item.image
      })
    }

    /* 博主 */
    let t = 0
    for (let i = 0; i < data.blogger.length; i++) {
      if(t >= 3){
        break
      }else{
        t++
      }
      let item = data.blogger[i]
      blogger.value.push({
        spu_id:item.spu_id,
        name:item.name,
        type:BloggerType2Name(item.type),
        img:item.image
      })
    }

    console.log(blogger.value)

    /* 艺术家 */
    t = 0
    for (let i = 0; i < data.designer.length; i++) {
      if(t >= 3){
        break
      }else{
        t++
      }
      let item = data.designer[i]
      designer.value.push({
        attribute_id:item.id,
        title:item.name,
        img:item.image
      })
    }
  })
})()
const jumpSku = function (sku_id, spu_id=""){
  router.push({path:"/product/",query:{spu:spu_id, sku:sku_id}})
}

const jumpSpu = function (spu_id){
  router.push({path:"/product/",query:{spu:spu_id}})
}

const jumpCollect = function (attributes){
  let query = {}
  query.brand_name = !!attributes.brand?Object.values(attributes.brand).join(','):''
  query.series_name = !!attributes.series?Object.values(attributes.series).join(','):''
  query.model_name = !!attributes.model?Object.values(attributes.model).join(','):''
  query.pattern_name = !!attributes.pattern?Object.values(attributes.pattern).join(','):''
  query.style_name = !!attributes.style?Object.values(attributes.style).join(','):''
  query.designer_name = !!attributes.designer?Object.values(attributes.designer).join(','):''
  query.co_name = !!attributes.co?Object.values(attributes.co).join(','):''
  query.special_name = !!attributes.special?Object.values(attributes.special).join(','):''
  query.influencer_name = !!attributes.influencer?Object.values(attributes.influencer).join(','):''
  router.push({path:"/collect/",query:query})
}

const pageJump = function (jump_type, data){
  console.log("pageJump ", jump_type, " ",data)
  switch (jump_type) {
    case 'sku':
      jumpSku(data.sku_id, data.spu_id)
      break;
    case 'spu':
      jumpSpu(data.spu_id)
      break;
    case 'collect':
      jumpCollect(data)
      break;
    case 'carousel':
      if(!data){
        return
      }
      let d = {}
      d[data.key]=[data.value]
      jumpCollect(d)
      break
    case 'special':
      getAttributeHeader(data).then(res=>{
        console.log("get header", res)
        if(res.hasOwnProperty('message') && res.message === "请求成功"){
          let b = {}
          b[res.data.attr_type] = [res.data.name]
          jumpCollect(b)
        }
      })
      break
    default:
      break;
  }
}
</script>

<template>
  <div class="home-page">
    <div class="carousel-box">
      <a-carousel
          :style="{
        width: '100%',
        height: '90vh',
      }"
          :default-current="1"
          indicator-type="none"
          auto-play="true"
          @change="handleChange"
      >
        <a-carousel-item v-for="item in carousel" @click="pageJump('carousel', item.attribute)" style="cursor:pointer;">
          <img
              :src="item.image"
              :style="{
            width: '100%',
            height:'100%'
          }"
          />
        </a-carousel-item>
      </a-carousel>
      <div style="text-align: center" >
        <div class="title" style="cursor:pointer;">
          {{carousel_out.title}}
        </div>
        <div class="subtitle" style="cursor:pointer;">
          {{carousel_out.subtitle}}
        </div>
        <a class="smooth-button" style="cursor:pointer;" @click="pageJump('carousel', carousel_out.attribute)">{{carousel_out.btntitle}}</a>
      </div>
    </div>

    <div class="marquee-unite-box">
      <Marquee :list="marquee_unite" collect_type="co"/>
    </div>

    <div class="display-box">
      <div class="display-top">
        精选系列
      </div>
      <div class="display-group">

        <template v-for="(item, index) in special">
          <div class="display-item" style="flex:calc(100% / 3);cursor: pointer" @click="pageJump('collect', {special:[item.name]})">
            <div>
              <img :src="item.img" alt="">
              <div class="content-title">{{item.name}}</div>
            </div>
            <div>
              <a class="smooth-button">了解更多</a>
            </div>
          </div>
        </template>
        <div class="display-item" style="flex:calc(100% / 3)" v-if="special.length < 3" v-for="_ in 3 - special.length" />

      </div>
    </div>

    <a-divider class="page-divider" />

    <div class="display-box">
      <div class="display-top" style="display: flex">
        <a>精选产品</a>
      </div>
      <MarqueeContent :list="special_product" collect_method="spu" collect_key="spu_id">
      </MarqueeContent>
    </div>

    <a-divider class="page-divider" />

    <div class="display-box">
      <div class="display-top" style="display: flex">
        <a>磁吸配件</a>
      </div>
      <MarqueeContent :list="magsafe" collect_method="spu" collect_key="spu_id">
      </MarqueeContent>
    </div>

    <a-divider class="page-divider" />

    <div class="display-box">
      <div class="display-top" style="display: flex">
        <a>热销配件</a>
      </div>
      <MarqueeContent :list="kits" collect_method="spu" collect_key="spu_id">
      </MarqueeContent>
    </div>

    <a-divider class="page-divider" />

    <div class="display-box">
      <div class="display-top" style="display: flex">
        <a>热销款式</a>
      </div>
      <MarqueeContent :list="hot" collect_method="spu" collect_key="spu_id">
      </MarqueeContent>
    </div>


    <a-divider class="page-divider" />

    <div class="display-box" :style="{
      height:blogger.length !== 0 ?'60rem':'',
      overflow:'hidden'
    }">
      <div class="display-top">
        Community
      </div>
      <div class="display-group">
        <template v-for="(item,index) in blogger">
          <div class="display-item" style="cursor: pointer" @click="pageJump('spu', {spu_id:item.spu_id})">
            <div>
              <img :src="item.img" alt="" style="
                height: 50rem;
                object-fit: cover;">
              <div class="content-title">{{item.type}}:{{item.name}}</div>
            </div>
          </div>
        </template>
        <div class="display-item" v-if="blogger.length!==0 && blogger.length < 3" v-for="_ in 3 - blogger.length"></div>

      </div>
    </div>

    <a-divider class="page-divider" />
    <div class="display-box" :style="{
      overflow: 'hidden',
      height: designer.length !== 0 ?'70rem':''
    }">
      <div class="display-top">
        艺术家
      </div>
      <div class="display-group">
        <template v-for="(item,index) in designer">
          <div class="display-item">
            <div style="position: relative;">
              <img :src="item.img" alt="" style="height: 60rem;object-fit: cover">
              <div class="content-dark">
                <div class="content-title content-stroke">{{ item.title }}</div>
                <div>
                  <a class="smooth-button" style="cursor:pointer;" @click="pageJump('collect', {designer:[item.title]})">立即选购</a>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
    </div>

    <a-divider class="page-divider" />


  </div>

</template>

<style scoped>
  .home-page{
    --page-padding-left: 7rem;
    --page-padding-right: 7rem;
  }

  .home-page .page-divider{
    margin-left: var(--page-padding-left);
    margin-right: var(--page-padding-right);
    max-width: 100%;
    width: unset;
    min-width:unset;
  }

  .carousel-box{
    padding-left: var(--page-padding-left);
    padding-right: var(--page-padding-right);
  }

  .carousel-box >>> .arco-carousel-arrow-right, .carousel-box >>> .arco-carousel-arrow-left{
    width: 7rem;
    height: 7rem;
    background-color: rgba(217,217,217,.75);
    color: #000;
  }

  .carousel-box >>> .arco-carousel-arrow-right{
    right: -3.5rem;
  }

  .carousel-box >>> .arco-carousel-arrow-left{
    left: -3.5rem;
  }

  .carousel-box >>> .arco-carousel-arrow-right svg, .carousel-box >>> .arco-carousel-arrow-left svg{
    font-size: 3.5rem;
    color: #000;
  }

  .carousel-box .title{
    font-size: 4.8rem;
    margin: 2.4rem 0 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    font-weight: 800;
  }

  .carousel-box .subtitle{
    line-height: 120%;
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    margin-top: 0;;
  }

  .marquee-unite-box{
    padding-left: var(--page-padding-left);
    padding-right: var(--page-padding-right);
    margin-top: 4.8rem;
  }

  .display-box{
    padding-left: var(--page-padding-left);
    padding-right: var(--page-padding-right);
    margin-top: 4.8rem;
    max-width: 100%;
  }

  .display-box .display-top{
    font-size: 2.4rem;
    font-weight: 700;
    color: #000;
    line-height: 2.9rem;
    margin-bottom: 2.8rem;
  }

  .display-box .display-group{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .display-box .display-item{
    flex: calc(100% / 3);
    position: relative;
    background-position: center;
    background-size: cover;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .display-box .display-item:last-child{
    margin-right: 0;
  }

  .display-box .display-item img{
    width: 100%;
  }

  .display-box .smooth-button{

  }

  .display-box .content-dark{
    position: absolute;
    bottom: 0;
    padding-bottom: 4.8rem;
    padding-left: 4.2rem;
    padding-right: 4.2rem;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }

  .display-box .content-stroke{
    text-shadow: 0 1px #ffffff, 1px 0 #ffffff, -1px 0 #ffffff, 0 -1px #ffffff;
  }

  .scroll-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 4rem;
    border-radius: 10rem;
    background-color: rgba(217,217,217,.3);
    opacity: .3;
    color: #000;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.3;
    user-select: none;
    padding-bottom: 0.5rem;
    font-weight: 900;
  }

  .large-image{
    position: relative;
    background-position: center;
    background-size: cover;
  }

  .large-image img{
    width: 100%;
  }

  .large-image .content{
    position: absolute;
    bottom: 0;
    padding: 0 4.2rem 4.2rem;
    backdrop-filter: blur(1px);
  }

  .large-image .content-title{
    font-weight: 800;
    font-size: 4.8rem;
    line-height: 4.8rem;
    color: #000;
    margin-bottom: 2.4rem;
    width: 100%;
    max-width: 100%;
  }

</style>