<template>
  <div class="menu-nav">
    <div style="display: flex">
      <div style="width: 20rem">
        <div class="title">{{ title }}</div>
        <ul>
          <template v-for="(item, index) in list">
            <li>
              <div class="text" v-on:mouseover="changeNav(index)" :style="{'color':index === action_nav?'#999':''}">{{item.title}}</div>
              <div><icon-right size="1.5rem" /></div>
            </li>
          </template>
        </ul>
        <div style="cursor: pointer">{{btn.title}}</div>
      </div>

      <a-divider style="margin-left: 2rem" direction="vertical" />

      <div style="margin-left: 2rem;width: 100%">
        <template v-for="(item, index) in list">
          <slot :name="'nav-' + item.id" v-if="index === action_nav"></slot>
        </template>
      </div>
    </div>




  </div>

</template>

<script>
import router from "@/router";
import { ref } from "vue";
export default {
  name: 'MenuNav',
  components:{

  },
  props:{
    list:{
      type:Array
    },
    title:{
      type:String
    },
    btn:{
      type:Object
    }
  },
  methods:{
  },
  setup(){
    const action_nav = ref(0)

    function changeNav(index){
      action_nav.value = index
    }
    return {action_nav, changeNav}
  }


}
</script>

<style scoped>
  .menu-nav{
    font-size: 1.5rem;
    padding-left: 20rem;
    padding-right: 20rem;
    background: #FFFFFF;
    padding-bottom: 5rem;
  }
  .menu-nav .title{
    font-size: 1.5rem;
    margin-bottom:  1.5rem;
    color: #999
  }
  .menu-nav ul{
    list-style: none;
    padding-left: 0;
    width: 100%;
  }

  .menu-nav li{
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: baseline;
  }

  .menu-nav li .text{
    margin-bottom: 1.8rem;
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    flex: 1;
  }

</style>
