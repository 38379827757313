<template>
  <div class="text-list">
    <a class="title">{{title}}</a>
    <ul>
      <li v-for="(item,index) in list" :key="index" @click="pageJump(_jump_type || item.jump_type, item[_jump_data || 'jump_data'], brand, series)">
        <span>{{ item.model_name }}</span>
      </li>
    </ul>
  </div>

</template>

<script>
import router from "@/router";
import { ref } from "vue";
export default {
  name: 'TextList',
  components:{

  },
  props:{
    title:{
      type:String
    },
    list:{
      type:Array
    },
    _jump_type:{
      type:String
    },
    _jump_data:{
      type:String
    },
    brand:{
      type:String
    },
    series:{
      type:String
    }
  },
  methods:{

  },
  setup(){
    function jumpSku(sku_id, spu_id=""){
      router.push({path:"/product/",query:{spu:spu_id, sku:sku_id}})
    }

    function jumpSpu(spu_id){
      router.push({path:"/product/",query:{spu:spu_id}})
    }

    function jumpCollect(attributes){
      let query = {}
      query.brand_name = !!attributes.brand?Object.values(attributes.brand).join(','):''
      query.series_name = !!attributes.series?Object.values(attributes.series).join(','):''
      query.model_name = !!attributes.model?Object.values(attributes.model).join(','):''
      query.pattern_name = !!attributes.pattern?Object.values(attributes.pattern).join(','):''
      query.style_name = !!attributes.style?Object.values(attributes.style).join(','):''
      query.designer_name = !!attributes.designer?Object.values(attributes.designer).join(','):''
      query.co_name = !!attributes.co?Object.values(attributes.co).join(','):''
      query.special_name = !!attributes.special?Object.values(attributes.special).join(','):''
      query.influencer_name = !!attributes.influencer?Object.values(attributes.influencer).join(','):''
      router.push({path:"/collect/",query:query})
    }


    function pageJump(jump_type, data, _brand=void 0, _series=void 0){
      console.log("pageJump", jump_type, " ", data)
      switch (jump_type) {
        case 'sku':
          jumpSku(data.sku_id, data.spu_id)
          break;
        case 'spu':
          jumpSpu(data.spu_id)
          break;
        case 'collect':
          jumpCollect(data)
          break;
        case 'brand':
          jumpCollect({brand:data})
          break;
        case 'model':
          jumpCollect({model:[data]})
          break;
        case '_textList':
          jumpCollect({model:[data], brand:[_brand], series:[_series]})
          break;
        default:
          break;
      }
    }

    return{pageJump}
  }


}
</script>

<style scoped>
  .text-list{
    font-size: 1.6rem;
  }
  .text-list .title{
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  .text-list ul{
    list-style: none;
    padding-left: 0;
  }
  .text-list li{
    //margin-bottom: 0.5rem;
    padding: 0.5rem 0;
    cursor: pointer;
  }
</style>
