import httpInstance from "../utils/http.js";
export function getHomePage(){
    return httpInstance({
        url: "/homepage/home/"
    })
}

export function getMenu(){
    return httpInstance({
        url: "/collect/menu/"
    })
}

export function getSpu(id){
    return httpInstance({
        url: "/product/spu/" + id
    })
}

export function getSku(id){
    return httpInstance({
        url: "/product/sku/" + id
    })
}

export function SkuFilter(data){
    return httpInstance({
        url: "/product/sku/filters?" + data
    })
}

export function getAttribute(type){
    return httpInstance({
        url: "/attributes/attributes?attr_type=" + type
    })
}

export function getAttributeHeader(id){
    return httpInstance({
        url: "/attributes/attributesHeader/" + id
    })
}

export function getBrand(){
    return httpInstance({
        url: "/collect/brand/"
    })
}

export function getSeries(){
    return httpInstance({
        url: "/collect/series/"
    })
}

export function getContactUsAPI(){
    return httpInstance({
        url: "/article/contactUs/"
    })
}

export function getMediaCoAPI(){
    return httpInstance({
        url: "/article/mediaCo/"
    })
}

export function getAboutUsAPI(){
    return httpInstance({
        url: "/article/aboutUs/"
    })
}

export function getRecruitAPI(){
    return httpInstance({
        url: "/article/recruit/"
    })
}

export function getStoreInfoAPI(){
    return httpInstance({
        url: "/article/storeInfo/"
    })
}

export function getSecurityCodeAPI(){
    return httpInstance({
        url: "/article/securityCode/"
    })
}

