<template>
  <div class="product-summary">
    <img @load="loaded()" :src="data.image" alt="" loading="lazy" :style="{height: loading?0:'unset',minHeight:loading?0:'26rem'}">
<!--    <img @load="loaded()" src="https://cdn-image02.casetify.com/usr/18718/38568718/~v12/30593079_samsung-galaxy-s23-plus__color_white_16005660.png.350x350-w.m80.webp" alt="" loading="lazy">-->
    <a-skeleton-shape :animation="true" v-if="loading" style="width: 70%;height: 26rem;" />
    <div class="summary">
      <p class="tag">
        MagSafe兼容
      </p>
      <p>
        {{ data.series }}
      </p>
      <p>
        {{data.spu_name}}
      </p>
      <p>
        {{data.collect}}
      </p>
      <p>
        ¥ {{data.price === "0.00"?"时价":data.price}}
      </p>
    </div>
  </div>

</template>

<script setup>
import router from "@/router";
import { ref } from "vue";

const props = defineProps({
  data: {
    type:Object,
    default:{
      tag:"magsafe",
      series:"iPhone 12 Pro Max",
      name:"MagSafe 兼容超强韧防摔手机壳",
      collect:"Puppy Pudding",
      price:"199",
      image:"https://ctgimage1.s3.amazonaws.com/cms/image/36d6eac69ba15d645c2f6e2015367af1.svg"
    }
  }
})

const loading = ref(true)

const loaded = function (){
    if(!props.data || !props.data.image){return}
    loading.value = false

}



</script>

<style scoped>

  .product-summary .tag:before{
    background: url("https://ctgimage1.s3.amazonaws.com/cms/image/36d6eac69ba15d645c2f6e2015367af1.svg");
  }

  .product-summary{
    text-align: center;
    text-align: -webkit-center;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .product-summary img{
    min-height: 26rem;
    max-height: 26rem;
    object-fit: cover;
  }

  .product-summary p{
    margin: 0.1rem;

  }

  .product-summary .summary{
    margin: unset;
    margin-top: 1.5rem;
    text-align: center;
    line-height: 1.2;
    font-weight: 600;
  }

  .product-summary .tag{
    color: #f15b41;
  }
</style>
