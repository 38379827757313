import axios from 'axios'

const httpInstance = axios.create({
    // baseURL: 'http://127.0.0.1:4523/m1/3113219-0-default',
    // baseURL: 'http://127.0.0.1:8000/api',
    // baseURL: 'https://nmax.yuheng.ac.cn/api',
    baseURL: '/api',
    timeout: 5000,
    // headers: {
    //     // 'Content-Type': 'application/x-www-form-urlencoded'
    //     // 'Content-Type': 'application/json'

    // },
    withCredentials:true
})


// 拦截器
// 请求拦截器
httpInstance.interceptors.request.use(config => {
    return config
}, e=> Promise.reject(e))

// 响应拦截器
httpInstance.interceptors.response.use(res => res.data,e => {
    return Promise.reject(e)
})


export default httpInstance