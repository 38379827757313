<script setup>
import sortCheckbox from "@/components/SortCheckbox.vue";
import productSummary from "@/components/ProductSummary.vue";
import {useRoute} from "vue-router";
import {defineComponent, onMounted, ref, watch} from "vue";
import router from "@/router";
import {getAttribute, getAttributeHeader, SkuFilter} from "@/api/api";
const route = useRoute()
const components = defineComponent({
  sortCheckbox,
  productSummary
})
const wall_img = ref("")
const wall_title = ref("")
const wall_describe = ref("")
const sort_list = ref([])

const product_list = ref([])
const result_loading = ref(true)
const set_header = ref(false)

const toParam = function (name, data){
  let result = ""
  for (let i = 0; i < data.length; i++) {
    result += name + "=" + data[i] + "&"
  }
  return result
}

const refreshSort = function (){
  let co = route.query.hasOwnProperty('co_name')?toParam("co_name",route.query.co_name.split(',')):'' // 联名
  let designer = route.query.hasOwnProperty('designer_name')?toParam("designer_name",route.query.designer_name.split(',')):'' // 设计师
  let pattern = route.query.hasOwnProperty('pattern_name')?toParam("pattern_name",route.query.pattern_name.split(',')):'' // 印花图案
  let style = route.query.hasOwnProperty('style_name')?toParam("style_name",route.query.style_name.split(',')):'' // 印花风格
  let brand = route.query.hasOwnProperty('brand_name')?toParam("brand_name",route.query.brand_name.split(',')):'' // 品牌
  let series = route.query.hasOwnProperty('series_name')?toParam("series_name",route.query.series_name.split(',')):'' // 系列
  let model = route.query.hasOwnProperty('model_name')?toParam("model_name",route.query.model_name.split(',')):'' // 机型
  let special = route.query.hasOwnProperty('special_name')?toParam("special_name",route.query.special_name.split(',')):'' // 精选系列
  let influencer = route.query.hasOwnProperty('influencer_name')?toParam("influencer_name",route.query.influencer_name.split(',')):'' //

  console.log({
    co_name:co,
    designer_name:designer,
    pattern_name:pattern,
    style_name:style,
    brand_name:brand,
    series_name:series,
    model_name:model
  })
  product_list.value.length = 0
  SkuFilter(co + designer + pattern + style + brand + series + model + special + influencer).then(res=>{
    result_loading.value = false
    console.log("筛选", res)
    product_list.value = res.data
  })
}
const updateTitle = function (){
  if(set_header.value){return}
  wall_title.value = "探索"
}

const refreshModelCheckbox = function (){
  //TODO
  let brand_name = route.query.hasOwnProperty('brand_name')?route.query.brand_name:'' // 品牌
  let series_name = route.query.hasOwnProperty('series_name')?route.query.series_name:'' // 系列
  let model_name = route.query.hasOwnProperty('model_name')?route.query.model_name:'' // 机型
  if(!brand_name && !series_name && !model_name){
    
  }
}

const initCheckbox = function (){
  function getCheckboxName(arr, sort_type){
    let result = []
    let selected = []
    if(sort_type !== void 0){
      selected = route.query.hasOwnProperty(sort_type)?route.query[sort_type].split(','):[]
    }
    for (let i = 0; i < arr.length; i++) {
      let is_select = selected.indexOf(arr[i].name) !== -1
      if(is_select && !set_header.value && selected.length === 1){
        set_header.value = true
        getAttributeHeader(arr[i].id).then(res=>{
          console.log("get header", res)
          if(res.message !== "请求成功"){return}
          let data = res.data
          wall_title.value = data.name
          wall_describe.value = data.description
          wall_img.value = data.image_l
        })
      }
      result.push({
        name:arr[i].name,
        selected:is_select
      })
    }
    return result
  }
  sort_list.value.length = 0
  set_header.value = false
  wall_title.value = ""
  wall_describe.value = ""
  wall_img.value = ""
  getAttribute("").then(res=>{
    console.log("All Attribute", res)
    let data = res.data
    if(!!data && !!data.results && data.results.length > 0){
      let attributes = data.results[0]
      if(attributes.hasOwnProperty("co")){sort_list.value.push({title:"联名系列",type:"co_name",data:getCheckboxName(attributes.co, "co_name")})}
      if(attributes.hasOwnProperty("designer")){sort_list.value.push({title:"艺术家",type:"designer_name",data:getCheckboxName(attributes.designer,"designer_name")})}
      if(attributes.hasOwnProperty("influencer")){sort_list.value.push({title:"影响力系列",type:"influencer_name",data:getCheckboxName(attributes.influencer,"influencer_name")})}
      if(attributes.hasOwnProperty("pattern")){sort_list.value.push({title:"印花图案",type:"pattern_name",data:getCheckboxName(attributes.pattern,"pattern_name")})}
      if(attributes.hasOwnProperty("style")){sort_list.value.push({title:"印花风格",type:"style_name",data:getCheckboxName(attributes.style,"style_name")})}
      if(attributes.hasOwnProperty("special")){sort_list.value.push({title:"精选系列",type:"special_name",data:getCheckboxName(attributes.special,"special_name")})}
    }
    updateTitle()
    console.log(sort_list)
  })
}

const refreshCheckbox = function (change_type, change_value){
  let query = {}
  query.co_name = route.query.hasOwnProperty('co_name')?route.query.co_name:'' // 联名
  query.designer_name = route.query.hasOwnProperty('designer_name')?route.query.designer_name:'' // 设计师
  query.pattern_name = route.query.hasOwnProperty('pattern_name')?route.query.pattern_name:'' // 印花图案
  query.style_name = route.query.hasOwnProperty('style_name')?route.query.style_name:'' // 印花风格
  query.brand_name = route.query.hasOwnProperty('brand_name')?route.query.brand_name:'' // 品牌
  query.series_name = route.query.hasOwnProperty('series_name')?route.query.series_name:'' // 系列
  query.model_name = route.query.hasOwnProperty('model_name')?route.query.model_name:'' // 机型
  query.special_name = route.query.hasOwnProperty('special_name')?route.query.special_name:'' // 精选系列
  query.influencer_name = route.query.hasOwnProperty('influencer_name')?route.query.influencer_name:'' //
  query[change_type] = change_value.join(",")

  router.push({path:"/collect/",query:query})
}

const jumpProduct = function (spu_id,sku_id){
  router.push({path:"/product/",query:{spu:spu_id, sku:sku_id}})
}

onMounted(()=>{
  result_loading.value = true
  refreshSort()
  initCheckbox()
})

watch(
    () => route.query,
    async _ => {
      result_loading.value = true
      refreshSort()
      initCheckbox()
    }
)
</script>

<template>
  <div class="page-series">
    <div class="wall">
      <img :src="wall_img" />
      <div style="width: 100%;height: 2rem"></div>
      <a-affix>
        <div class="wall-title">{{wall_title}}</div>
      </a-affix>
      <div>
        <div class="wall-subtitle">
          {{wall_describe}}
        </div>
      </div>
    </div>

    <div class="container" style="display: flex;margin-top: 50px;">
      <div class="sort">
        <a-skeleton :animation="true" style="width: 80%" v-if="result_loading">
          <a-skeleton-line :rows="6" />
          <div style="height: 5rem;width: 100%"></div>
          <a-skeleton-line :rows="3" />
          <div style="height: 5rem;width: 100%"></div>
          <a-skeleton-line :rows="3" />
          <div style="height: 5rem;width: 100%"></div>
          <a-skeleton-line :rows="6" />
          <div style="height: 5rem;width: 100%"></div>
          <a-skeleton-line :rows="4" />
        </a-skeleton>
        <template v-for="(item, index) in sort_list">
          <sort-checkbox :data="item.data" :title="item.title" :type="item.type" @change="refreshCheckbox"></sort-checkbox>
        </template>

      </div>
      <div class="items">

        <a-grid :cols="4" :colGap="12" :rowGap="16">
          <a-grid-item style="width: 26rem" v-if="result_loading">
            <div>
              <a-skeleton :animation="true" >
                <a-skeleton-shape style="width: 100%;height: 26rem;" />
                <div style="height: 2rem;width: 100%"></div>
                <a-skeleton-line :rows="4" :line-height="10" />
              </a-skeleton>
            </div>
          </a-grid-item>
          <template v-for="(item,index) in product_list">
            <a-grid-item style="width: 26rem" @click="jumpProduct(item.spu_id, item.sku_id)"><product-summary :data="item"></product-summary></a-grid-item>

          </template>

        </a-grid>
      </div>
    </div>
  </div>
</template>

<style scoped>

  .page-series{
    padding: 0 8vw;
  }

  .wall img{
    width: 100%;
  }

  .wall-title{
    font-size: 3.6rem;
    line-height: 1.1;
    letter-spacing: 0.072rem;
    font-weight: 700;
    transition: transform .2s ease 0s;
    word-break: break-word;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #FFF;
  }

  .wall-subtitle{
    font-size: 1.5rem;
    line-height: 1.4;
    display: block;
    padding-top: 2.4rem;
  }

  .page-series .container .sort{
    width: 25vw;
  }

  .page-series .container .items{
    flex: 1 1 0;
  }
</style>