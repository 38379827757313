<script setup>
  import ProductCarousel from "@/components/ProductCarousel.vue";
  import {defineComponent, onMounted, ref, watch} from "vue";
  import {useRoute} from "vue-router";
  import {getSku, getSpu} from "@/api/api";

  const components = defineComponent({
      ProductCarousel
  })

  const route = useRoute()


  const spu = ref({
    carousel_images:[],
    title:"",
    tag:"",
    description:"",
  })

  const sku = ref({
    price: 199,
  })

  const brand_selected = ref("")
  const model_selected = ref("")
  const select_option = ref({})
  const href_link = ref("")


  const toOption = function (brand_list){
    let result = {}
    for (let i = 0; i < brand_list.length; i++) {
      let brand = brand_list[i]
      let series = brand.seriesList
      let skus = []
      for (let j = 0; j < series.length; j++) {
        let model = series[j].modelList
        for (let k = 0; k < model.length; k++) {
          let sku = model[k]
          if(!sku.sku_id){
            continue
          }
          skus.push(sku)
        }
      }
      result[brand.id] = {
        name:brand.brand_name,
        skus:skus
      }
    }
    console.log("result", result)
    return result
  }

  const getSkuById = function (){
    getSku(model_selected.value).then(res=>{
      console.log("getSku", res)
      sku.value.price = res.data.price === "0.00"?' 时价':res.data.price
      href_link.value = res.data.link
      spu.value.carousel_images.length = 0
      if(res.data.image){
        for (let i = 0; i < res.data.image.length; i++) {
          spu.value.carousel_images.push(res.data.image[i].image)
        }
      }

    })
  }

  const getSpuById = function (spu_id, sku_id){
    getSpu(spu_id).then(res=>{
      console.log(res)
      let data = res.data
      spu.value.title = data.spu_name
      spu.value.description = data.description
      switch (data.tag){
        case "b3c39c6d-3393-4556-a66a-7a21c29c5bdf":
          spu.value.tag = "magsafe"
          break
        case "bca27191-6f34-426f-bcca-6ff5a848185c":
          spu_id.value.tag="recovery"
          break
        default:
          spu.value.tag = ""
      }
      select_option.value = toOption(data.brandList)
      initSelectOption(sku_id, data)
      getSkuById()
    })
  }

  const brandChange = function (){
    if(!select_option.value[brand_selected.value]){
      return
    }
    model_selected.value = select_option.value[brand_selected.value].skus[0].sku_id
    getSkuById()
  }

  const modelChange = function (){
    getSkuById()
  }

  const initSelectOption = function (sku_id = null, spu_list=null){
    if(!sku_id){
      brand_selected.value = Object.keys(select_option.value)[0]
    }else{
      for (let i = 0; i < spu_list.brandList.length; i++) {
        for (let j = 0; j < spu_list.brandList[i].seriesList.length; j++) {
          for (let k = 0; k < spu_list.brandList[i].seriesList[j].modelList.length; k++) {
            let item = spu_list.brandList[i].seriesList[j].modelList[k]
            if(item.sku_id === sku_id){
              brand_selected.value = spu_list.brandList[i].id
              model_selected.value = sku_id
              return
            }
          }
        }
      }
    }

    console.log(select_option)
    brandChange()
  }

  const refreshProduct = function (){
    let spu_id = route.query.hasOwnProperty("spu")?route.query.spu:""
    let sku_id = route.query.hasOwnProperty("sku")?route.query.sku:""
    if(!spu_id && !sku_id){
      return
    }

    if(spu_id && sku_id){
      getSpuById(spu_id, sku_id)

      return
    }

    getSpuById(spu_id, sku_id)
  }

  const toBuy = function (){
    window.open(href_link.value,'_blank')
  }

  onMounted(()=>{
    refreshProduct()
  })

  watch(
      () => route.query,
      async _ => {
        refreshProduct()
      }
  )

</script>

<template>
  <div class="product-page">
    <div class="detail">
      <div class="carousel">
        <ProductCarousel :images="spu.carousel_images"/>
      </div>
      <div class="sale" style="margin-left: 4rem">
        <div class="tag">
          <template v-if="spu.tag === 'magsafe'">
            <img data-v-6935d244="" src="//cdn.casetify.cn/static/cms/image/47390/pdp-2023-magsafe-icon.png" class="magsafe">
            兼容MagSafe
          </template>

        </div>

        <div class="title">
          <h1>{{spu.title}}</h1>
        </div>

        <div class="price">
          ¥{{sku.price}}
        </div>

        <div class="model-select">
          <p>选择型号：</p>
          <div style="display: flex">
            <a-select :style="{width:'210px'}" v-model="brand_selected" @change="brandChange()">
              <a-option v-for="value of Object.keys(select_option)" :value="value">{{select_option[value].name}}</a-option>
            </a-select>
            <a-select :style="{marginLeft:'1rem'}" :options="select_option.hasOwnProperty(brand_selected)?select_option[brand_selected].skus : []" :field-names="{value:'sku_id', label:'model_name'}" v-model="model_selected" @change="modelChange()" />
          </div>
        </div>

        <div style="margin-top: 2rem">
          <a class="smooth-button" :style="{
            width: '100%',
            textAlign: 'center',
            paddingRight: 0,
            paddingLeft: 0,
            lineHeight: '4rem',
          }" @click="toBuy">立即购买</a>
        </div>
      </div>

    </div>

  </div>
  <div>
    <div v-html="spu.description"></div>
  </div>
</template>

<style scoped>
  .product-page{
    padding: 0 8vw;
  }

  .product-page .detail{
    display: flex;
    padding: 0 10vw;
  }
  
  .product-page .detail .carousel{
    flex: 0 0 50%;;
  }

  .product-page .sale .tag{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #f15b41;
    margin: unset;
  }

  .product-page .sale .tag .magsafe{
    width: 30px;
    height: 30px;
    margin: 0 5px 0 0;
  }

  .product-page .sale .title{
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #000;
    font-size: 2.1rem;
  }

  .product-page .sale .price{
    font-size: 2.2rem;
    font-weight: 500;
    color: #000;
    box-sizing: border-box;
  }

  .product-page .sale .model-select p{
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
  }


  .product-page >>> .arco-select-view-single{
    color: #000;
    font-size: 1.7rem;
    font-weight: 500;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 4px;
    height: unset!important;
    padding: 1.2rem 1.2rem;
    letter-spacing: 0.02rem;
    border: 1px solid #000000;
    background-color: #FFF;
    text-align: left;
  }

  .product-page >>> .arco-select-view-single.arco-select-view-size-medium .arco-select-view-value{
    font-size: 1.7rem;
  }
</style>