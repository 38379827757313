<script>
  import {ref} from "vue";
  import router from "@/router";

  export default {
    name:"IconList",
    props:{
      list:{
        type:Array
      },
      title:{
        type:String
      },
      btn:{
        type:Object
      }
    },
    setup(){
      const action_nav = ref(0)

      function changeNav(index){
        action_nav.value = index
      }

      function jumpCollect(attributes){
        let query = {}
        query.brand_name = !!attributes.brand?Object.values(attributes.brand).join(','):''
        query.series_name = !!attributes.series?Object.values(attributes.series).join(','):''
        query.model_name = !!attributes.model?Object.values(attributes.model).join(','):''
        query.pattern_name = !!attributes.pattern?Object.values(attributes.pattern).join(','):''
        query.style_name = !!attributes.style?Object.values(attributes.style).join(','):''
        query.designer_name = !!attributes.designer?Object.values(attributes.designer).join(','):''
        query.co_name = !!attributes.co?Object.values(attributes.co).join(','):''
        router.push({path:"/collect/",query:query})
      }

      return {action_nav, changeNav, jumpCollect}
    }
  }
</script>

<template>
  <div class="icon-list">
    <a class="title">{{title}}</a>
    <p></p>
    <a-grid :cols="5" :colGap="12" :rowGap="16">
      <template v-for="(item, index) in list" :key="index">
        <a-grid-item style="cursor:pointer;" @click="jumpCollect({co:[item.title || item.name]})">
          <a-avatar :imageUrl="item.image || item.image_s" :size="70"></a-avatar>
          <a style="text-align: center" :style="{
            paddingLeft:'1rem'
          }">{{ item.title || item.name }}</a>
        </a-grid-item>
      </template>
    </a-grid>
  </div>
</template>

<style scoped>
  .icon-list{
    font-size: 1.6rem;
    width: 95%;
  }
  .icon-list .title{
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .icon-list img{
    height: 25rem;
    width: 100%;
    object-fit: cover;
  }
</style>